import React, { useState, useCallback, useRef } from 'react';
import Modal from 'antd/lib/modal';
import { useDispatch, useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';

import { Flex } from 'src/styled/Layout';
import { closeGiphy, closeGiphyEdit } from 'src/components/Giphy/giphyAction';
import { Card as C, Image as I, Button as B } from 'src/styled/Component';
import { closeImages, closeImagesEdit } from 'src/components/ImageComment/imageCommentAction';
import { SVG } from './SVG';
import { icons } from '@src/static';
import { lazyLoadingImages, lazyLoadingCss } from 'src/common/utils';
import { loadDrafts, saveDraftStatus } from '@src/utils/function';
import { IAppState } from '@src/interface/IAppState';

interface Props {
  src: any;
  isStatus?: boolean;
  gifphy?: string;
  unPreview?: boolean;
  idStatus?: number;
  content?: string;
  feeling?: {
    id: number;
    name: string;
    icon: string;
  };
}

const ImagesContainer: React.FC<Props> = (props) => {
  const { src, isStatus, gifphy, unPreview, idStatus, content, feeling } = props;
  const dispatch = useDispatch();
  const { isEdit } = useSelector((state: IAppState) => state.statusState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [numberImg, setNumberImg] = useState<number>(0);

  const imgRef = useRef(null);
  let refImg: any;
  const closeImage = (id: number) => {
    if (isEdit) {
      dispatch(closeGiphyEdit());
      dispatch(closeImagesEdit(id));
    } else {
      dispatch(closeGiphy());
      dispatch(closeImages(id));
    }
    const draft = loadDrafts('DRAFT_STATUS');
    if (draft['new']) {
      draft['new'].gif = null;
      localStorage.setItem('DRAFT_STATUS', JSON.stringify(draft));
    }
    if (idStatus) {
      saveDraftStatus({
        id: String(idStatus),
        feeling: feeling as any,
        content: content,
        gif: null as any
      });
    }
  };

  const newImages =
    src &&
    src.map((item: any) => {
      const data = {
        original: item.url,
        thumbnail: item.url,
        sizes: "width: '100%'"
      };
      return data;
    });

  const openPreviewsImages = (id: number) => () => {
    if (unPreview) return;
    if (!isStatus) return;
    setNumberImg(id);
    setIsOpen(true);
  };

  const onClickOutSide = (event: any) => {
    if (event.target && event.target.tagName === 'IMG') return;
    setIsOpen(false);
  };

  const setOnClickOutside = useCallback(() => {
    if (!isOpen || !src) return;
    const gallery = document.getElementsByClassName('image-gallery-swipe');
    if (gallery.length) {
      gallery[0].removeEventListener('click', onClickOutSide, false);
      gallery[0].addEventListener('click', onClickOutSide, false);
    }
  }, [src, isOpen]);

  const closePreview = () => {
    if (!refImg) return;
    refImg.exitFullScreen();
    setIsOpen(false);
  };

  const renderFullscreenButton = (onClickAction: any, isFullscreen: boolean) => {
    setOnClickOutside();
    return (
      <div className={`image-gallery-fullscreen-button ${isFullscreen ? 'active' : ''}`}>
        {!isFullscreen && (
          <B.BtnZoom onClick={onClickAction}>
            <SVG strokeWidth={2} viewBox={'0 0 24 24'} icon={'maximize'} />
          </B.BtnZoom>
        )}
        <B.BtnZoom style={{ marginLeft: 24 }} onClick={closePreview}>
          <SVG strokeWidth={2} viewBox={'7 8 22 15'} icon={'closes'} />
        </B.BtnZoom>
      </div>
    );
  };

  return (
    <Flex>
      {gifphy ? (
        <I.ImageContentStatus src={gifphy}>
          <div className="bg-content-status" />
          <div className="bg-blur-status" />
          <img src={gifphy} alt="status" ref={imgRef} />
        </I.ImageContentStatus>
      ) : (
        <Flex wraper={true} className={`items-wrap wrap-${src.length}`}>
          {src.map((item: any, index: number) => {
            return (
              <div className="item-img" key={index} onClick={openPreviewsImages(index)}>
                {!isStatus && (
                  <C.CardNative
                    style={{ width: 'initial', left: 'initial', right: 0 }}
                    onClick={() => closeImage(item.id)}>
                    <img src={icons.ICloseButton} alt="" width={16} />
                  </C.CardNative>
                )}
                <I.ImagesCover src={item.url} className="default-img" alt="" ref={imgRef} />
              </div>
            );
          })}
        </Flex>
      )}
      {newImages && (
        <Modal
          centered
          footer={false}
          width={'100%'}
          closable={false}
          visible={isOpen}
          bodyStyle={{ padding: 0 }}
          className={`modal-preview-images`}
          wrapClassName="modal-preview-images"
          onOk={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}>
          <ImageGallery
            lazyLoad
            slideDuration={0}
            items={newImages}
            showThumbnails={false}
            showPlayButton={false}
            startIndex={numberImg}
            ref={(ref) => (refImg = ref)}
            renderFullscreenButton={renderFullscreenButton}
          />
        </Modal>
      )}
    </Flex>
  );
};

export default ImagesContainer;
