import styled from 'styled-components';

interface Props {
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  src?: string;
  rotate?: number;
}

export const VideoOverlay = styled.div<Props>`
  position: absolute;
  z-index: 1;
  background-color: transparent;
  transform: translate(-50%, -50%) ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : ``)};
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${({ left }) => (left ? `${left}px` : `initial`)};
  top: ${({ top }) => (top ? `${top}px` : `initial`)};
  width: ${({ width }) => (width ? `${width}px` : `initial`)};
  height: ${({ height }) => (height ? `${height}px` : `initial`)};

  video {
    width: 100%;
    outline: none;
  }
`;
