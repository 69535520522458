import styled from 'styled-components';

interface ButtonProps {
  br?: number;
  size?: number;
  src?: string;
  fw?: number;
  font?: string;
}

export const ToolbarContainer = styled.div`
  position: absolute;
  left: 5px;
  bottom: 5px;
  background-color: #474747;
  border-radius: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 3px;
`;

export const Button = styled.button<ButtonProps>`
  min-width: ${({ size }) => (size ? `${size}px` : `30px`)};
  height: ${({ size }) => (size ? `${size}px` : `30px`)};
  border-radius: ${({ br }) => (br ? `${br}px` : `0`)};
  outline: none;
  background-size: 16px;
  background-color: transparent;
  background-position: center;
  background-image: url('${(props) => props.src}');
  background-repeat: no-repeat;
  color: #d5d5d5;
  cursor: pointer;
  border: none;
  transition: 0.5s ease;
  padding: 0 5px;
  font-weight: ${({ fw }) => (fw ? fw : 400)};
  font-family: ${({ font }) => (font ? font : '')};
  :focus {
    outline: 0;
  }
  :hover {
    background-color: #343434;
  }
`;
