// Because `videojs-contrib-quality-levels` not support typescript, use this file in jsx
import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import qualitySelector from 'videojs-hls-quality-selector';
import qualityLevels from 'videojs-contrib-quality-levels';
import 'videojs-thumbnail-sprite';
import 'video.js/dist/video-js.min.css';
import { useSession } from 'next-auth/react';

function VideoJS(props) {
  const { data: session } = useSession();
  const {
    height,
    videoURL,
    thumbnail,
    animatedThumbnail,
    playing,
    videoWidth,
    videoHeight,
    preview,
    duration,
    previewFramesInterval
  } = props;
  const refContainer = useRef(null);
  const videoNode = useRef(null);

  const [player, setPlayer] = useState(null);
  const [isReady, setReady] = useState(null);

  useEffect(() => {
    if (window && window.IS_LOAD_VIDEOJS_PLUGIN) {
      videojs.registerPlugin('qualityLevels', qualityLevels);
      videojs.registerPlugin('hlsQualitySelector', qualitySelector);
      window.IS_LOAD_VIDEOJS_PLUGIN = true;
    }
  }, []);

  useEffect(() => {
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: videoURL
        }
      ],
      height,
      poster: thumbnail,
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        hls: {
          withCredentials: false,
          cacheEncryptionKeys: true,
          overrideNative: true
        }
      }
    };

    let player = videojs(videoNode.current, videoJsOptions, () => {
      setReady(true);

      if (player.tech().hls) {
        player.tech().hls.xhr.beforeRequest = function (options) {
          const token = session ? session.access_tonken : '';

          if (options.uri.indexOf('.key') !== -1) {
            if (!options.headers) {
              options.headers = {};
            }

            options.headers = {
              ...options.headers,
              Authorization: token
            };
          }

          return options;
        };
      }
    });

    setPlayer(player);

    player.hlsQualitySelector({ displayCurrentQuality: true });

    if (preview) {
      player.thumbnailSprite({
        sprites: [
          {
            url: preview,
            interval: previewFramesInterval,
            width: (120 / videoHeight) * videoWidth,
            height: 120,
            start: 0,
            duration: duration
          }
        ]
      });
    }

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [
    videoURL,
    thumbnail,
    height,
    videoHeight,
    videoWidth,
    preview,
    duration,
    previewFramesInterval
  ]);

  useEffect(() => {
    if (!isReady) return;

    if (playing) {
      player.play();
    } else {
      player.pause();
    }
  }, [isReady, player, playing]);

  useEffect(() => {
    let container = refContainer.current;
    const onmouseover = (e) => {
      if (player && animatedThumbnail) player.poster(animatedThumbnail);
    };

    const onmouseout = (e) => {
      if (player) player.poster(thumbnail);
    };

    if (container) {
      container.addEventListener('mouseover', onmouseover);
      container.addEventListener('mouseout', onmouseout);
    }
    return () => {
      if (container) {
        container.removeEventListener('mouseover', onmouseover);
        container.removeEventListener('mouseout', onmouseout);
      }
    };
  }, [refContainer, player, animatedThumbnail, thumbnail]);

  return (
    <div>
      <div ref={refContainer} data-vjs-player style={{ margin: '0 auto' }}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={videoNode} className="video-js vjs-big-play-centered"></video>
      </div>
    </div>
  );
}

export default VideoJS;
