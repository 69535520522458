import React from 'react';

interface Props {
  strokeWidth: number;
  viewBox: string;
  icon: string;
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
}

const maximize = (
  <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
);
const minimize = (
  <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
);
const closes = (
  <>
    <path
      d="M24 8L8 24"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8L24 24"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

const chatsCircle = (
  <>
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M226.24072,189.28271A76.00889,76.00889,0,0,0,166.75732,76.316a75.99462,75.99462,0,1,0-136.998,64.96667l-6.6377,23.2334a9.99929,9.99929,0,0,0,12.36133,12.36328l23.23438-6.63818A75.8485,75.8485,0,0,0,89.21,179.69043a76.04093,76.04093,0,0,0,108.07275,38.55029l23.23438,6.63867a9.9987,9.9987,0,0,0,12.36133-12.3623ZM59.25146,161.92822a3.99738,3.99738,0,0,0-1.09863.15381l-24.86719,7.105a2,2,0,0,1-2.47265-2.47265l7.10449-24.86719a4.00117,4.00117,0,0,0-.40723-3.14307,67.97064,67.97064,0,1,1,23.78614,23.78613A4.00029,4.00029,0,0,0,59.25146,161.92822Zm165.42676,54.75a1.96577,1.96577,0,0,1-1.96386.50879l-24.86719-7.10449a3.99913,3.99913,0,0,0-3.14356.4082,68.0381,68.0381,0,0,1-96.70752-30.54126A75.97949,75.97949,0,0,0,169.50244,84.6731a68.01461,68.01461,0,0,1,48.98828,102.03051,3.99743,3.99743,0,0,0-.4082,3.14307l7.10449,24.86865A1.96026,1.96026,0,0,1,224.67822,216.67822Z"></path>
    </g>
  </>
);

const iconMapper = {
  maximize,
  minimize,
  closes,
  chatsCircle
};

const SVG: React.FC<Props> = (props) => {
  const { strokeWidth, viewBox, icon, className, width, height, fill } = props;
  return (
    <svg
      className={`${className ? className : ''} image-gallery-svg`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill={fill ? fill : 'none'}
      width={`${width}px`}
      height={`${height}px`}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round">
      {iconMapper[icon]}
    </svg>
  );
};

const ChatSVG: React.FC<Props> = (props) => {
  const { viewBox, className, width, height } = props;
  return (
    <svg
      className={`${className ? className : ''} image-gallery-svg`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="none"
      stroke="currentColor"
      width={`${width}px`}
      height={`${height}px`}>
      <path
        d="M6.5 10.75L4.19356 13.0853C3.87184 13.411 3.71098 13.5739 3.57271 13.5854C3.45276 13.5953 3.33531 13.5471 3.25697 13.4557C3.16667 13.3504 3.16667 13.1215 3.16667 12.6637V11.4937C3.16667 11.083 2.83031 10.7858 2.4239 10.7262V10.7262C1.44031 10.5822 0.667832 9.80969 0.523756 8.8261C0.5 8.66391 0.5 8.47039 0.5 8.08333V4.6C0.5 3.33988 0.5 2.70982 0.745235 2.22852C0.96095 1.80516 1.30516 1.46095 1.72852 1.24524C2.20982 1 2.83988 1 4.1 1H9.65C10.9101 1 11.5402 1 12.0215 1.24524C12.4448 1.46095 12.789 1.80516 13.0048 2.22852C13.25 2.70982 13.25 3.33988 13.25 4.6V7.75M13.25 16L11.6177 14.8652C11.3882 14.7056 11.2735 14.6259 11.1487 14.5693C11.0378 14.5191 10.9213 14.4826 10.8017 14.4606C10.6669 14.4357 10.5271 14.4357 10.2477 14.4357H8.9C8.05992 14.4357 7.63988 14.4357 7.31901 14.2722C7.03677 14.1284 6.8073 13.8989 6.66349 13.6167C6.5 13.2958 6.5 12.8758 6.5 12.0357V10.15C6.5 9.30992 6.5 8.88988 6.66349 8.56901C6.8073 8.28677 7.03677 8.0573 7.31901 7.91349C7.63988 7.75 8.05992 7.75 8.9 7.75H13.1C13.9401 7.75 14.3601 7.75 14.681 7.91349C14.9632 8.0573 15.1927 8.28677 15.3365 8.56901C15.5 8.88988 15.5 9.30992 15.5 10.15V12.1857C15.5 12.8846 15.5 13.2341 15.3858 13.5097C15.2336 13.8773 14.9416 14.1693 14.574 14.3215C14.2984 14.4357 13.9489 14.4357 13.25 14.4357V16Z"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ThreeDotVerticalSVG: React.FC<Props> = (props) => {
  const { viewBox, className, width, height, fill, stroke } = props;
  return (
    <svg
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      className={`bi bi-three-dots-vertical ${className ? className : ''}`}
      stroke={stroke}
      width={`${width}px`}
      height={`${height}px`}>
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>{' '}
      </g>
    </svg>
  );
};

export { SVG, ChatSVG, ThreeDotVerticalSVG };
