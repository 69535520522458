export default [
  'Nunito Sans',
  'Amatic SC',
  'Bangers',
  'Bungee Hairline',
  'Bungee Outline',
  'Bungee Shade',
  'Grandstander',
  'Itim',
  'Lemonada',
  'Lobster',
  'Major Mono Display',
  'Open Sans',
  'Pacifico',
  'Pattaya',
  'Roboto',
  'Roboto Slab',
  'Sansita Swashed',
  'Sedgwick Ave',
  'Sigmar One',
  'VT323'
];
