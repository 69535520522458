export interface Sticker {
  url: string;
}

export interface StickerCategories {
  name: string;
  icon: string;
  stickers: Sticker[];
}

const dataInfos = [
  {
    key: 'baach',
    count: 44
  },
  {
    key: 'batman_v_superman',
    count: 16
  },
  {
    key: 'bigli_migli',
    count: 24
  },
  {
    key: 'biscuit_in_love',
    count: 16
  },
  {
    key: 'bold_betakkuma',
    count: 24
  },
  {
    key: 'brown_and_friends',
    count: 20
  },
  {
    key: 'chicken_bro',
    count: 16
  },
  {
    key: 'chin_su',
    count: 20
  },
  {
    key: 'chummy_chum_chums',
    count: 16
  },
  {
    key: 'daily_duncan_vol1',
    count: 16
  },
  {
    key: 'despicable_me_2',
    count: 40
  },
  {
    key: 'doodlings',
    count: 24
  },
  {
    key: 'fiery_chicken_bro',
    count: 16
  },
  {
    key: 'happy_mimi_and_neko',
    count: 24
  },
  {
    key: 'hello_brown',
    count: 20
  },
  {
    key: 'hyper_usagyuuun',
    count: 24
  },
  {
    key: 'koko',
    count: 44
  },
  {
    key: 'meep',
    count: 31
  },
  {
    key: 'mim_on_the_move',
    count: 20
  },
  {
    key: 'simons_cat',
    count: 20
  },
  {
    key: 'skeleton_crew',
    count: 16
  },
  {
    key: 'speedy_usagyuuun',
    count: 24
  },
  {
    key: 'sweet_qoobee',
    count: 24
  },
  {
    key: 'taz',
    count: 16
  },
  {
    key: 'the_defenders',
    count: 16
  },
  {
    key: 'tonton_friends_2',
    count: 48
  },
  {
    key: 'trash_doves',
    count: 24
  },
  {
    key: 'wrestlers_rematch',
    count: 24
  }
];

const getStickerCategories = (): StickerCategories[] => {
  const categories: StickerCategories[] = [];

  dataInfos.forEach((dataInfo) => {
    const category: StickerCategories = {
      name: dataInfo.key,
      icon: `/images/stickers/${dataInfo.key}/${dataInfo.key}_00.png`,
      stickers: []
    };

    for (let i = 0; i < dataInfo.count; i++) {
      category.stickers.push({
        url: `/images/stickers/${dataInfo.key}/${dataInfo.key}_${i.toString().padStart(2, '0')}.png`
      });
    }

    categories.push(category);
  });

  return categories;
};

export default getStickerCategories();
