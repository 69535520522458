import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Upload, { UploadChangeParam } from 'antd/lib/upload';
import fbr from 'fabric';
import Popover from 'antd/lib/popover';
import Tooltip from 'antd/lib/tooltip';
import List from 'antd/lib/list';
import Typography from 'antd/lib/typography';
import message from 'antd/lib/message';
import Spin from 'antd/lib/spin';
import DeleteFilled from '@ant-design/icons/DeleteFilled';
import FontColorsOutlined from '@ant-design/icons/FontColorsOutlined';
import SmileFilled from '@ant-design/icons/SmileFilled';
import HeartFilled from '@ant-design/icons/HeartFilled';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import VideoCameraFilled from '@ant-design/icons/VideoCameraFilled';
import { ChromePicker, ColorResult } from 'react-color';
import { Picker, EmojiData, CustomEmoji, BaseEmoji } from 'emoji-mart';
import emojiConfig from 'src/components/Emoji/config';
import { VideoOverlay } from 'src/styled/Component/Fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { ToolbarContainer, Button } from 'src/styled/App/Toolbar';
import StickerPicker, { ISticker } from 'src/components/StickerPicker';
import { parseURL } from 'src/common/utils';
import fabricFonts from './font';
import FontFaceObserver from 'fontfaceobserver';
import { icons } from '@src/static';
import { requestUploadVideoFabric } from 'src/activities/status/statusAction';
import { IAppState } from 'src/interface/IAppState';
import { IstatusState, UploadFileType } from 'src/interface/IStatusState';
import VideoJS from 'src/components/VideoPlayer/VideoJS';

const { fabric } = fbr;

export interface Props {
  background?: string;
  onInit?: (canvas: fabric.Canvas) => void;
  jsonData?: string;
  hideToolbar?: boolean;
  editable?: boolean;
  maxWidth?: number;
  isPost?: boolean;
}

interface FabricVideoData {
  left: number;
  top: number;
  width: number;
  height: number;
  src: string;
  angle: number;
}

const Fabric: React.FC<Props> = (props: Props) => {
  const { editor, onReady } = useFabricJSEditor();
  const [oldJSONData, setOldJSONData] = useState<string | undefined>(undefined);
  const [isLoadInitText, setIsLoadInitText] = useState<boolean>(false);
  const [isLoadJSON, setIsLoadJSON] = useState<boolean>(false);
  const refContainer = useRef<HTMLDivElement>(null);
  const [visibleStickerPopover, setVisibleStickerPopover] = useState<boolean>(false);
  const [visibleEmojiPopover, setVisibleEmojiPopover] = useState<boolean>(false);
  const [isInitEmojiPicker, setIsInitEmojiPicker] = useState<boolean>(false);
  const [hasBackground, setHasBackground] = useState<boolean>(false);
  const [selectedFont, setSelectedFont] = useState<string>(fabricFonts[0]);
  const [fabricVideoDatas, setFabricVideoDatas] = useState<FabricVideoData[]>([]);
  const dispatch = useDispatch();

  const cloneFabricObject = (target: any) => {
    const canvas = target.canvas;
    target.clone(function (cloned: any) {
      cloned.left += 10;
      cloned.top += 10;
      canvas.add(cloned);
    });
  };

  console.log(props.isPost, 'isPost');

  const deleteFabricObject = (target: any) => {
    const canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
  };

  const registerControls = () => {
    const imgDelete = document.createElement('img');
    imgDelete.src = icons.CloseImg;

    const imgCopy = document.createElement('img');
    imgCopy.src = icons.IIconCopy;

    function renderDeleteIcon(
      ctx: any,
      left: any,
      top: any,
      styleOverride: any,
      fabricObject: any
    ) {
      const size = 20;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(imgDelete, -size / 2, -size / 2, size, size);
      ctx.restore();
    }

    function renderCopyIcon(ctx: any, left: any, top: any, styleOverride: any, fabricObject: any) {
      const size = 20;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(imgCopy, -size / 2, -size / 2, size, size);
      ctx.restore();
    }

    const fabricTmp: any = fabric;
    const deleteControl = new fabricTmp.Control({
      x: 0.5,
      y: -0.5,
      offsetX: 18,
      offsetY: -18,
      cursorStyle: 'pointer',
      mouseUpHandler: (eventData: any, target: any) => {
        deleteFabricObject(target.target);
      },
      render: renderDeleteIcon,
      cornerSize: 24
    });

    const copyControl = new fabricTmp.Control({
      x: -0.5,
      y: -0.5,
      offsetX: -18,
      offsetY: -18,
      cursorStyle: 'pointer',
      mouseUpHandler: (eventData: any, target: any) => {
        cloneFabricObject(target);
      },
      render: renderCopyIcon,
      cornerSize: 24
    });

    const fabricObjectPrototype: any = fabric.Object.prototype;
    const fabricITextPrototype: any = fabric.IText.prototype;
    const fabricTextboxPrototype: any = fabric.Textbox.prototype;
    const fabricTextPrototype: any = fabric.Text.prototype;

    fabricObjectPrototype.controls.deleteControl = deleteControl;
    fabricITextPrototype.controls.deleteControl = deleteControl;
    fabricTextboxPrototype.controls.deleteControl = deleteControl;
    fabricTextPrototype.controls.deleteControl = deleteControl;

    fabricObjectPrototype.controls.copyControl = copyControl;
    fabricITextPrototype.controls.copyControl = copyControl;
    fabricTextboxPrototype.controls.copyControl = copyControl;
    fabricTextPrototype.controls.copyControl = copyControl;
  };

  const addVideo = useCallback(
    (
      canvas: fabric.Canvas,
      url: string,
      left?: number,
      top?: number,
      width?: number,
      height?: number,
      scaleX?: number,
      scaleY?: number,
      angle?: number
    ): Promise<fabric.Image> => {
      return new Promise((resolve) => {
        if (props.editable) {
          const videoElem = document.createElement('video');
          videoElem.autoplay = true;
          videoElem.loop = true;
          videoElem.addEventListener(
            'loadedmetadata',
            () => {
              videoElem.width = videoElem.videoWidth;
              videoElem.height = videoElem.videoHeight;

              const videoFabric = new fabric.Image(videoElem, {
                name: 'video-' + Date.now(),
                left: left || 200,
                top: top || 200,
                angle: angle || 0,
                originX: 'center',
                originY: 'center',
                objectCaching: false,
                crossOrigin: 'Anonymous'
              });

              videoFabric.on('removed', () => {
                videoElem.pause();
              });

              if (width && height) {
                videoFabric.width = width;
                videoFabric.height = height;
              } else {
                videoFabric.scaleToWidth(400);
              }

              if (scaleX) {
                videoFabric.scaleX = scaleX;
              }

              if (scaleY) {
                videoFabric.scaleY = scaleY;
              }

              canvas.add(videoFabric);

              // requestAnimFrame
              fabric.util.requestAnimFrame(function render() {
                if (canvas.getObjects('image').length > 0) {
                  canvas.renderAll();
                  fabric.util.requestAnimFrame(render);
                }
              });

              resolve(videoFabric);
            },
            false
          );
          videoElem.src = url;
        } else {
          // load view
          const zoom = canvas.getZoom();
          setFabricVideoDatas([
            ...fabricVideoDatas,
            {
              left: zoom * (left || 200),
              top: zoom * (top || 200),
              width: zoom * (width || 0) * (scaleX || 1),
              height: zoom * (height || 0) * (scaleY || 1),
              src: url,
              angle: angle || 0
            }
          ]);

          resolve(null as any);
        }
      });
    },
    [fabricVideoDatas, props.editable]
  );

  const _onReady = (canvas: fabric.Canvas) => {
    onReady(canvas);
    registerControls();
    canvas.enableRetinaScaling = true;
    if (props.onInit) props.onInit(canvas);
  };

  const scaleToFit = useCallback(() => {
    const canvas = editor?.canvas;

    const width = refContainer.current?.clientWidth || 0;
    const height = (width / 750) * 520;

    canvas?.setWidth(width);
    canvas?.setHeight(height);

    if (canvas && canvas.backgroundImage) {
      const backgroundWidth = (canvas.backgroundImage as fabric.Image).width || 0;
      const backgroundHeight = (canvas.backgroundImage as fabric.Image).height || 0;

      const scaleX = (canvas?.width || 0) / backgroundWidth;
      const scaleY = (canvas?.height || 0) / backgroundHeight;
      const scale = Math.max(scaleX, scaleY);
      canvas.setZoom(scale);
    }
  }, [refContainer, editor]);

  const onPressDeleteKey = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.code === 'Backspace' && evt.key === 'Backspace') {
        const activeObject = editor?.canvas.getActiveObject();
        if (activeObject) {
          if (activeObject.type === 'textbox') {
            const text = activeObject as fabric.Textbox;
            if (text.isEditing) return;
          }
          deleteFabricObject(activeObject);
        }
      }
    },
    [editor]
  );

  const onPressCloneKey = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.code === 'KeyC' && evt.key === 'c') {
        const activeObject = editor?.canvas.getActiveObject();
        if (activeObject) {
          if (activeObject.type === 'textbox') {
            const text = activeObject as fabric.Textbox;
            if (text.isEditing) return;
          }
          cloneFabricObject(activeObject);
        }
      }
    },
    [editor]
  );

  // componentWillUnmount
  useEffect(() => {
    return () => {
      editor?.canvas.getObjects().forEach((fabricObject) => {
        if (fabricObject.type === 'image') {
          const fabricImage = fabricObject as fabric.Image;
          const fabricElem = fabricImage.getElement();
          if (fabricElem instanceof HTMLVideoElement) {
            fabricElem.pause();
          }
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  // listen resize event
  useEffect(() => {
    window.addEventListener('resize', scaleToFit);
    window.addEventListener('keyup', onPressDeleteKey);
    window.addEventListener('keyup', onPressCloneKey);

    return () => {
      window.removeEventListener('resize', scaleToFit);
      window.removeEventListener('keyup', onPressDeleteKey);
      window.removeEventListener('keyup', onPressCloneKey);
    };
  }, [scaleToFit, onPressDeleteKey, onPressCloneKey]);

  // load from json
  useEffect(() => {
    const canvas = editor?.canvas;

    if (props.jsonData !== oldJSONData && props.jsonData && editor && !props.isPost) {
      canvas && canvas.clear();
      if (fabricVideoDatas.length > 0) {
        setFabricVideoDatas([]);
        return;
      }

      const fabricData = JSON.parse(props.jsonData);
      canvas &&
        canvas.loadFromJSON(props.jsonData, () => {
          if (canvas) {
            canvas.renderAll.bind(canvas)();
            scaleToFit();

            // add video
            const listPromiseVideos: Promise<fabric.Image>[] = [];
            fabricData.objects.forEach((fabricDataObject: any) => {
              if (fabricDataObject.name?.indexOf('video-') === 0) {
                listPromiseVideos.push(
                  addVideo(
                    canvas as fabric.Canvas,
                    fabricDataObject.src,
                    fabricDataObject.left,
                    fabricDataObject.top,
                    fabricDataObject.width,
                    fabricDataObject.height,
                    fabricDataObject.scaleX,
                    fabricDataObject.scaleY,
                    fabricDataObject.angle
                  )
                );
              }
            });

            Promise.all(listPromiseVideos).then(() => {
              setIsLoadJSON(true);
            });
          }
        });

      setOldJSONData(props.jsonData);
    }
  }, [editor, oldJSONData, props.jsonData, scaleToFit, addVideo, fabricVideoDatas, props.isPost]);

  const openURL = (e: fabric.IEvent) => {
    if (e.target?.type === 'textbox') {
      const textBox = e.target as fabric.Textbox;
      const urls = parseURL(textBox.text || '');

      if (urls.length > 0) {
        window.open(urls[0], '_blank');
      }
    }
  };

  // editable
  useEffect(() => {
    const canvas = editor?.canvas;
    if (!props.editable && canvas && isLoadJSON) {
      // disable select
      const objects = canvas.getObjects();
      objects.forEach((obj) => {
        obj.selectable = false;
        obj.moveCursor = 'arrow';
        obj.hoverCursor = 'arrow';

        if (obj.type === 'textbox') {
          const textBox = obj as fabric.Textbox;

          if (parseURL(textBox.text || '').length > 0) {
            textBox.hoverCursor = 'pointer';
          }
        }
      });
      canvas.selection = false;

      canvas.on('mouse:down', openURL);

      return () => {
        canvas?.off('mouse:down', openURL);
      };
    }
  }, [props.editable, isLoadJSON, editor]);

  // set background
  useEffect(() => {
    const canvas = editor?.canvas;

    if (props.background && canvas) {
      scaleToFit();

      fabric.Image.fromURL(
        props.background as string,
        (img) => {
          const scaleY = (canvas?.getHeight() || 1) / (img.height || 1);
          const scaleX = (canvas?.getWidth() || 1) / (img.width || 1);
          img.set({
            originX: 'left',
            originY: 'top'
          });
          canvas?.setBackgroundImage(img, canvas?.renderAll.bind(canvas));
          canvas?.setZoom(Math.max(scaleX, scaleY));

          setTimeout(() => {
            setHasBackground(true);
          }, 100);
        },
        {
          crossOrigin: 'Anonymous'
        }
      );
    }
  }, [editor, props.background, scaleToFit]);

  const handleClickAddText = useCallback(() => {
    const object = new fabric.Textbox('Double click to edit', {
      fontSize: 36,
      fontFamily: selectedFont,
      fontWeight: 'bold',
      fill: '#fff',
      width: 400,
      textAlign: 'center'
    });

    const realCanvasWidth = (editor?.canvas?.width || 0) / (editor?.canvas?.getZoom() || 0);
    const realCanvasHeight = (editor?.canvas?.height || 0) / (editor?.canvas?.getZoom() || 0);

    object.set({
      left: (realCanvasWidth - (object.width || 0)) / 2,
      top: (realCanvasHeight - (object.height || 0)) / 2
    });

    editor?.canvas.add(object);
  }, [editor, selectedFont]);

  const handleClickDelete = () => {
    editor?.deleteSelected();
  };

  const onChangeColor = (color: ColorResult) => {
    editor?.canvas.getActiveObject()?.set('fill', color.hex);
  };

  // add first text
  useEffect(() => {
    if (!isLoadInitText && !isLoadJSON && editor && hasBackground) {
      handleClickAddText();
      setIsLoadInitText(true);
    }
  }, [editor, isLoadInitText, isLoadJSON, handleClickAddText, hasBackground]);

  const onSelectSticker = (sticker: ISticker) => {
    fabric.Image.fromURL(sticker.url as string, (img) => {
      const realCanvasWidth = (editor?.canvas?.width || 0) / (editor?.canvas?.getZoom() || 0);
      const realCanvasHeight = (editor?.canvas?.height || 0) / (editor?.canvas?.getZoom() || 0);

      img.set({
        left: (realCanvasWidth - (img.width || 0)) / 2,
        top: (realCanvasHeight - (img.height || 0)) / 2
      });

      editor?.canvas.add(img);
    });

    setVisibleStickerPopover(false);
  };

  const onSelectEmoji = (emojiData: EmojiData) => {
    const customEmoji: CustomEmoji = emojiData as CustomEmoji;
    const realCanvasWidth = (editor?.canvas?.width || 0) / (editor?.canvas?.getZoom() || 0);
    const realCanvasHeight = (editor?.canvas?.height || 0) / (editor?.canvas?.getZoom() || 0);

    if (customEmoji.imageUrl) {
      fabric.Image.fromURL(customEmoji.imageUrl as string, (img) => {
        img.set({
          left: (realCanvasWidth - (img.width || 0)) / 2,
          top: (realCanvasHeight - (img.height || 0)) / 2
        });

        editor?.canvas.add(img);
      });
    }

    const baseEmoji: BaseEmoji = emojiData as BaseEmoji;
    if (baseEmoji.native) {
      const object = new fabric.Textbox(baseEmoji.native, {
        fontSize: 24,
        width: 50,
        textAlign: 'center'
      });

      object.set({
        left: (realCanvasWidth - (object.width || 0)) / 2,
        top: (realCanvasHeight - (object.height || 0)) / 2
      });

      editor?.canvas.add(object);
    }

    setVisibleEmojiPopover(false);
  };

  const onSelectFont = (font: string) => {
    const myfont = new FontFaceObserver(font);
    myfont
      .load()
      .then(() => {
        let activeTextbox: fabric.Textbox | null = null;

        const activeObject = editor?.canvas.getActiveObject();
        if (activeObject) {
          if (activeObject.type === 'textbox') {
            activeTextbox = activeObject as fabric.Textbox;
          }
        } else {
          const textboxes = editor?.canvas.getObjects('textbox') as fabric.Textbox[];
          if (textboxes.length === 1) {
            activeTextbox = textboxes[0];
          }
        }

        if (activeTextbox) {
          activeTextbox.set('fontFamily', font);
          editor?.canvas.requestRenderAll();

          setSelectedFont(font);
        }
      })
      .catch(() => {
        message.error('Failed to load font ' + font);
      });
  };

  const handleChangeVideo = (info: UploadChangeParam) => {
    setIsUploadingVideo(true);
    dispatch(requestUploadVideoFabric([info.file]));
  };

  const { uploadVideoStatus }: IstatusState = useSelector((state: IAppState) => state.statusState);

  const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);

  useEffect(() => {
    if (
      isUploadingVideo &&
      uploadVideoStatus.success &&
      uploadVideoStatus.fileType === UploadFileType.VIDEO &&
      !!uploadVideoStatus.fileURL
    ) {
      addVideo(editor?.canvas as fabric.Canvas, uploadVideoStatus.fileURL);
      setIsUploadingVideo(false);
    }
  }, [
    addVideo,
    editor,
    isUploadingVideo,
    uploadVideoStatus.success,
    uploadVideoStatus.fileURL,
    uploadVideoStatus.fileType
  ]);

  const renderListFont = () => {
    return (
      <List
        size="small"
        bordered
        dataSource={fabricFonts}
        renderItem={(fabricFont) => (
          <List.Item
            key={fabricFont}
            style={{ cursor: 'pointer' }}
            onClick={() => onSelectFont(fabricFont)}>
            <Typography.Text style={{ fontFamily: fabricFont }}>{fabricFont}</Typography.Text>
          </List.Item>
        )}
      />
    );
  };

  return (
    <div
      ref={refContainer}
      style={{
        position: 'relative',
        maxWidth: props.maxWidth ? props.maxWidth : '',
        margin: '0 auto'
      }}>
      {
        // fabric videos
        fabricVideoDatas.map((fabricVideoData, index) => (
          <VideoOverlay
            key={`${index}-video`}
            width={fabricVideoData.width}
            height={fabricVideoData.height}
            left={fabricVideoData.left}
            top={fabricVideoData.top}
            rotate={fabricVideoData.angle}>
            {/* <video controls src={fabricVideoData.src}/> */}
            <VideoJS
              width={fabricVideoData.width}
              height={fabricVideoData.height}
              videoURL={fabricVideoData.src}
              preview={false}
            />
          </VideoOverlay>
        ))
      }
      <FabricJSCanvas className="fabric-canvas" onReady={_onReady} />
      {!props.hideToolbar && (
        <ToolbarContainer>
          <Tooltip placement="top" title="Delete">
            <Button size={25} br={3} onClick={handleClickDelete}>
              <DeleteFilled />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Sticker">
            <Popover
              placement="top"
              content={<StickerPicker onSelect={onSelectSticker} />}
              overlayClassName="popover-color-picker"
              trigger="click"
              visible={visibleStickerPopover}
              onVisibleChange={setVisibleStickerPopover}>
              <Button size={25} br={3}>
                <HeartFilled />
              </Button>
            </Popover>
          </Tooltip>
          <Tooltip placement="top" title="Emoji">
            <Popover
              placement="bottom"
              content={
                isInitEmojiPicker ? (
                  <Picker {...emojiConfig} onSelect={onSelectEmoji} />
                ) : (
                  <div
                    style={{
                      width: 340,
                      height: 430,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                  </div>
                )
              }
              overlayClassName="popover-color-picker"
              trigger="click"
              visible={visibleEmojiPopover}
              onVisibleChange={(visible) => {
                setVisibleEmojiPopover(visible);

                if (visible && !isInitEmojiPicker) {
                  setTimeout(() => {
                    setIsInitEmojiPicker(true);
                  }, 1000);
                }
              }}>
              <Button size={25} br={3}>
                <SmileFilled />
              </Button>
            </Popover>
          </Tooltip>
          <Tooltip placement="top" title="Textbox">
            <Button size={25} br={3} fw={900} onClick={handleClickAddText}>
              T
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Video">
            <Upload
              accept="video/*"
              showUploadList={false}
              multiple={false}
              onChange={handleChangeVideo}
              beforeUpload={() => false}>
              <Button size={25} br={3} fw={900}>
                <VideoCameraFilled />
              </Button>
            </Upload>
          </Tooltip>
          <Tooltip placement="top" title="Color">
            <Popover
              placement="top"
              content={<ChromePicker onChange={onChangeColor} />}
              overlayClassName="popover-color-picker"
              trigger="click">
              <Button size={25} br={3}>
                <FontColorsOutlined />
              </Button>
            </Popover>
          </Tooltip>
          <Tooltip placement="top" title="Font family">
            <Popover
              placement="right"
              content={renderListFont()}
              overlayClassName="popover-font-picker"
              trigger="click">
              <Button size={25} br={3} font={selectedFont}>
                {selectedFont}
              </Button>
            </Popover>
          </Tooltip>
        </ToolbarContainer>
      )}
    </div>
  );
};

export default Fabric;
